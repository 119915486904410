body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  /*font-family: Arial, sans-serif;*/
  text-align: center;
  margin-bottom: 5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

h1 {
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 6px;
  margin-top: 50px;
}

.logo {
  height: 1.25em;
}

.mortgage-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  gap: 40px;
}

.mortgage-form label {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
  margin-bottom: 10px;
  width: 100%;
  max-width: 150px;
}

.mortgage-form label input {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 5px;
}

.mortgage-form label input:focus {
  outline: none;
  border-color: #1e88e5;
  box-shadow: 0 0 5px rgba(30, 136, 229, 0.5);
}

.mortgage-form button {
  padding: 12px 24px;
  background-color: #1e88e5;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.mortgage-form button:hover {
  background-color: #1565c0;
}

@media screen and (max-width: 600px) {
  .mortgage-form {
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }

  .mortgage-form label {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .mortgage-form label input {
    width: 100%;
  }

  #yearly_breakdown,
  #summary {
    padding: 0 20px;
  }
}

#yearly_breakdown h3 {
  margin-bottom: 20px;
}

#yearly_breakdown table {
  margin: 0 auto 50px auto;
  border-collapse: collapse;
}

#yearly_breakdown table th, #yearly_breakdown table td {
  border: 1px solid black;
  padding: 8px;
}

#summary {
  margin: 50px auto;
  text-align: left;
  max-width: 40em;
}

#summary table {
  width: 100%;
  margin: 20px auto;
  border-collapse: collapse;
  border: 1px solid #ccc;
}

#summary th, #summary td {
  padding: 12px;
  border: 1px solid #ccc;
}

#summary th {
  background-color: #f4f4f4;
  font-weight: bold;
}

#summary td {
  text-align: left;
}

#summary h3 {
  margin-top: 30px;
  color: #333;
}

.note {
  font-size: 0.9em;
  font-style: italic;
}

.tip {
  font-size: 0.9em;
  background-color: #f0f7ff;
  border-left: 4px solid #1e88e5;
  padding: 15px;
  margin: 20px auto 0 auto;
  text-align: left;
  line-height: 1.5;
}

.tip strong {
  color: #1e88e5;
}
